.subscribe-button {
    background-color: #FF00D6; /* Vibrant pink to match the example */
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.subscribe-button:hover {
    box-shadow: 0 4px 8px rgba(255, 0, 214, 0.4);
    transform: scale(1.05);
}

.subscribe-button:active {
    transform: scale(0.98);
}