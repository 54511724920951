.App {
  text-align: center;
  font-family: "GilroyLight";
}

.hoox-text {
  text-align: center;
  font-family: "GilroyLight";
  color: white;
}


/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

/*
.ticketbutton {
  padding: 10px 20px;
  border-width: 3px;
  width: 100%;
  background-color: #bc00bc;
  border-color: #bc00bc;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 700;
}
.ticketbutton:hover,
.ticketbutton:focus {
  background-color: #bc00bc;
  border-color: #bc00bc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.detailbutton {
  padding: 10px 20px;
  border-width: 3px;
  width: 100%;
  background-color: transparent;
  border-color: #ffffff;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 700;
}
*/
.detailbutton:hover,
.detailbutton:focus {
  background-color: transparent;
  border-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.iconbutton {
  background-color: transparent;
  border-width: 3px;
  font-size: 17px;
  border-color: #ffffff;
  border-radius: 10px;
  font-weight: 700;
}
.iconbutton:hover,
.iconbutton:focus {
  background-color: transparent;
  border-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.categoryList {
  text-align: center !important;
  margin-right: 50px;
  display: block !important;
}
.showAllText {
  font-size: 11px;
  color: #7f759e;
  font-weight: 600;
}
.popularText {
  color: #7f759e;
  font-weight: 600;
}
.watchText {
  color: #7f759e;
  font-weight: 600;
}

.watchArea {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 520px;
}
.searchDiv {
  background-color: #262331;
  height: 50px;
}
.searchInput {
  background-color: transparent;
  width: 100%;
  border: none;
  font-size: 18px;
  margin-top: 10px;
}
.checkOutButton {
  padding: 10px 20px;
  border-width: 3px;
  /* width: 100%; */
  background-color: #bc00bc;
  border-color: #bc00bc;
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 1px;
}
.checkOutButton:hover,
.checkOutButton:focus {
  background-color: #bc00bc;
  border-color: #bc00bc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* styles.css veya App.css gibi bir dosyada */
.checkOutButton:disabled {
  background-color: #bb6dbb; /* Disabled durumdayken arkaplan rengi */
  color: white; /* Disabled durumdayken metin rengi */
  cursor: not-allowed; /* Mouse imlecini gösterme */
  border-color: #bb6dbb;
  border-width: 3px;
}



.cancelButton {
  padding: 10px 20px;
  border-width: 3px;
  /* width: 100%; */
  background-color: #bface294;
  border-color: transparent;
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 1px;
}
.cancelButton:hover,
.cancelButton:focus {
  background-color: #bface2;
  border-color: #bface2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.favIcon {
  background-color: transparent;
  border-width: 3px;
  font-size: 17px;
  border-color: #ffffff;
  border-radius: 10px;
  font-weight: 700;
}
.courTitle {
  font: normal normal 800 40px/50px "Montserrat";
  text-align: left;
}
.courContent {
  font: normal normal 400 18px "Montserrat";
  text-align: left;
}
.courDateTime {
  text-align: left;
  font: normal normal 800 16px "Montserrat";
}


/* 
@media only screen and (max-width: 600px) {
  .courTitle {
    text-align: center;
  }
  .courContent {
    text-align: center;
  }
  .courDateTime {
    text-align: center;
  }
  .courBtn {
    display: flex !important;
    justify-content: center !important;
  }
}
*/

.login-register {
  background-size: cover;
  height: 80vh;
  opacity: 0.8;
}
.welcome {
  background: linear-gradient(tobottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background-size: cover;
  display: inline-block;
  position: relative;
  width: 100%;
}


.nav-tabs .nav-link {
  background: unset !important;
  border: unset !important;
}
.nav {
  display: unset !important;
  flex-wrap: unset !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #bc00bc !important;
}
.nav-tabs a {
  cursor: pointer;
  margin-top: 10px;
  color: white;
  font: normal normal 800 18px Montserrat;
}
a {
  cursor: pointer;
}

.lr-input {
  background-color: transparent;
  width: 100%;
  border: none;
  font-size: 18px;
  margin-top: -10px;
  margin-top: 0px !important;
  padding: 10px 20px;
  color: rgb(127, 117, 158);
  font: normal normal normal 12px Montserrat;
  background: #2b2738 0% 0% no-repeat padding-box;
}
.package-select {
  background-color: transparent;
  width: 100%;
  border: none;
  font-size: 18px;
  margin-top: -10px;
  margin-top: 0px !important;
  padding: 10px 20px;
  color: rgb(127, 117, 158);
  font: normal normal normal 12px Montserrat;
  background: #2b2738 0% 0% no-repeat padding-box;
}

.form-control {
  background: 0% 0% no-repeat padding-box padding-box rgb(43, 39, 56) !important;
  border: 2px solid rgb(17, 213, 223, 70%);
  /*border: rgb(89, 81, 116) !important;*/
  border-radius: 0px !important;
  width: 100% !important;
  color: #dfdee2 !important;
}

.selected-flag {
  background: 0% 0% no-repeat padding-box padding-box rgb(43, 39, 56) !important;
}
.country-list {
  background: 0% 0% no-repeat padding-box padding-box rgb(43, 39, 56) !important;
}
.flag-dropdown {
  background: 0% 0% no-repeat padding-box padding-box rgb(43, 39, 56) !important;
  border: unset !important;
}

.modal-header .btn-close {
  background-color: white !important;
  margin-bottom: 15px;
}
.modal-content {
  background-color: rgb(0, 7, 58, 0.85) !important;
  border: 1px solid rgba(18, 214, 223, 0.3);
  /* width: 80%; */
}
.img-area img {
  opacity: 0.7;
}

.contentNav .nav-tabs .nav-item.show .nav-link,
.contentNav .nav-tabs .nav-link.active {
  background-color: #252330 !important;
  color: white !important;
}
.CategoryNav a.active.nav-link {
  color: #bc00bc !important;
}
.text-clip {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.contentNav i.fa {
  display: inline-block;
  border-radius: 60px;
  padding: 0.5em 0.6em;
  font-size: 20px;
  background-color: #ee115e;
}

@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"),
    url("./fonts/Gilroy-Medium.ttf") format("truetype");
}

.font-face-gilroy-medium {
  font-family: "GilroyMedium";
  color: white;
  font-size: 1.9em;
  letter-spacing: 20px;
}

@font-face {
  font-family: "GilroyLight";
  src: local("GilroyLight"), url("./fonts/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url("./fonts/Gilroy-Regular.otf") format("truetype");
}

@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"), url("./fonts/Gilroy-Bold.otf") format("truetype");
}

.font-face-gilroy-light {
  font-family: "GilroyLight";
  color: white;
  font-size: 1.3em;
}

.modal-90w {
  max-width: none;
  width: 80%;
}

.main-footer {
  color: #ffffff;
  font-family: "GilroyLight";
}

/* .content-detail-header{
  color: #ffffff;
  font-family: "GilroyMedium";
  font-size: 84px;
  font-weight: bold;
  margin-bottom: 20px;
  box-sizing: content-box;
  line-height: 110%;
}

.content-detail-desc{
	margin-top: 10%;
} */

.content-detail-date {
  font-size: 20px;
}

.content-detail-genre {
  font-size: 18px;
  font-weight: 300;
}

.content-detail-genres {
  margin-top: 5%;
  font-size: 22px;
  font-weight: 500;
}

.content-detail-definition {
  margin-left: 40%;
  font-size: 15px;
}

.content-detail-imdb {
  margin-left: 40%;
  font-size: 12px;
}

.content-detail-actors {
  font-weight: 300;
  margin-top: 5%;
  font-size: 18px;
}

.content-detail-actors-mobile {
  font-weight: 300;
  margin-top: 2%;
  font-size: 15px;
  font-family: "GilroyLight";
}

.content-detail-synop {
  margin-top: 7%;
  font-weight: 300;
  line-height: 110%;
  font-size: 18px;
  font-family: "GilroyRegular";
}

.content-detail-play {
  vertical-align: middle;
  font-size: 18px;
  font-family: "GilroyBold";
}

.label-title {
  color: #ffffff;
  font-family: "GilroyMedium";
  font-size: 25px;
  margin-left: 90px;
}

.label-title-mobile {
  color: #ffffff;
  font-family: "GilroyLight";
  font-size: 20px;
  margin-left: 10px;
}

/* 
@media (max-width: "500px") {
  .label-title-test {
    color: #c06363;
    font-family: "GilroyLight";
    font-size: 10px;
  }
}
*/

.icon-flipped::before {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}


.hoox-button {
  font-family: "GilroyLight";
  color: white;
  border: 2px solid #bc00bc;
  padding: 8px 12px;
  width: 200px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #bc00bc;
  text-align: center;
}

.swiper-pagination {
  padding-bottom: 10px;
}

.swiper-pagination-bullet {
  background-color: #c0bbbb !important;
}

.swiper-pagination-bullet-active {
  background-color: #bc00bc !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color:#dfdee2;
}


/* styles.css veya App.css gibi bir dosyada */
.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3); 
  border-radius: 50%;
  border-top: 4px solid rgb(220, 221, 221);
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.words-swiper {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
}

.words-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-button:not(.collapsed){
  background-color:  rgba(255, 255, 255, 0.3); 
  font-family: "GilroyLight";
  color: white;
  font-size: large;

}

.accordion-header button{
  background-color:  rgba(255, 255, 255, 0.3); 
  font-family: "GilroyLight";
  color: white;
  font-size: large;
}

.custom-radio input[type="radio"] {
  appearance: none; /* Varsayılan stil kaldırılır */
  width: 20px;      /* Buton genişliği */
  height: 20px;     /* Buton yüksekliği */
  border: 2px solid #12d6df; /* Kenarlık rengi */
  border-radius: 5px; /* Yuvarlaklık miktarı, sıfır yaparsanız kare olur */
  position: relative;
  cursor: pointer;
  font-family: "GilroyLight";
  color: white;
  font-size: large;
}

.custom-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: #bc00bc; /* İç dolgu rengi */
  transform: translate(-50%, -50%);
  border-radius: 5px; /* İç dolgunun yuvarlaklık miktarı */
}

.form-check-input:checked{
  background-color: #bc00bc; /* İç dolgu rengi */
}

.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.custom-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;     /* Checkmark ile aynı boyutta */
  height: 20px;    /* Checkmark ile aynı boyutta */
  opacity: 0;      /* Görünmez yapıyoruz */
  z-index: 2;      /* Tıklanabilir olmasını sağlamak için önde tutuyoruz */
  margin: 0;
  cursor: pointer;
}

/* Özel checkbox kutusu */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.162);       /* Varsayılan arka plan */
  border: 2px solid rgb(203, 203, 220);        /* Border rengi */
  border-radius: 4px;            /* İsteğe bağlı: yuvarlatılmış köşeler */
}

/* Checkbox işaretli olduğunda */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #bc00bc;        /* İşaretli durum arka planı */
}

/* İşaret (tik) oluşturmak için */
.custom-checkbox input:checked ~ .checkmark::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}