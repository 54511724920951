
body {
    background-color: #00073af2;
    font-family: Arial, Helvetica, sans-serif;
  }
  h1 {
    color: white;
    line-height: 1.5;
  }
  h2 {
    color: white;
    align-self: flex-start;
    line-height: 1.5;
  }
  h3 {
    color: white;
    align-self: flex-start;
    line-height: 1.5;
  }
  h4 {
    color: white;
    align-self: flex-start;
  }
  h5 {
    color: white;
    align-self: flex-start;
    line-height: 1.5;
  }
  p {
    color: white;
    align-self: flex-start;
  }
  ul {
    color: white;
    align-self: flex-start;
  }
  ol {
    color: white;
    align-self: flex-start;
  }
  