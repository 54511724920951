.header {
    /* position: inherit; */
    display: flex;
    /* padding: 20px 0; */
    background: rgb(146,1,159);
    background: linear-gradient(135deg, rgba(146,1,159,.95) 0%, rgba(0,7,58,.95) 35%);
}


.menu-item {
    font-family: "GilroyLight";
    color: #fffaff;
    font-size: 20px;
    margin-left: 1.5rem;
}

.login-button {
    font-family: "GilroyLight";
    color: #ffffff;
    border: 1px solid #BC00BC;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    padding: 8px 12px;
    width: 200px;
    text-align: center;
    background-color: #00073a;
    font-weight: 500;
    
}

.howto-button {
    font-family: "GilroyLight";
    color: #ffffff;

    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
    padding: 8px 12px;
    width: 230px;
    text-align: center;
}

.category-menu {
    position: absolute;
    color: white;
    top: 100%;
    left: 2.5%;
    background-color: rgba(255, 255, 255, 0.26);
    width: auto;
    height: 200px;
    z-index: 9999999;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 18px;
}

.category-group {
    border-right: 2px solid white;
    height: 100%;
}

.register-button{
    font-family: "GilroyLight";
    color: white;
    border: 1px solid #BC00BC;
    padding: 8px 12px;
    width: 200px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #BC00BC;
    text-align: center;
    font-weight: 500;

}

.register-button:hover{
    border: 1px solid #BB00BB;
}

.navbar-collapse {
    background-color: transparent; /* varsayılan transparan arka plan rengi */
    margin-top: 10px;

  }
  
  .navbar-expand-md .navbar-collapse.show {
    background-color: rgba(0,7,58,.95); /* Navbar açıldığında arka plan rengi */
    margin-top: 20px;
  }

 
  