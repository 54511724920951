.NavMenuItemStyled .nav-link {
    color: #fff;
    border-radius: 20px;
  }
  
  .NavMenuItemStyled .nav-link.active {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }
  
  .NavMenuItemStyled:hover {
    color: #fff;
  }
  
  .NavUserNameBoxStyled {
    color: #fff;
    border-bottom: 1px solid #12d6df;
    margin-bottom: 17px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .TabTitle {
    font-size: 23px;
    font-weight: 500;
  }
  
  .TabContent {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    padding: 30px;
    font-size: 13px;
  }
  
  .formcontrol-user {
    background-color: rgb(0 7 58) !important;
    border-radius: 7px !important;
    resize: none;
    height: 40px;
    font: normal normal normal 14px GilroyLight;
    /*border: 1px solid rgba(18 214 223 / 30%); */
  }
  
  .FormControl:focus-visible {
    outline: rgb(0 7 58) !important;
  }
  
  .FormControlMask {
    background-color: rgb(0 7 58) !important;
    border-radius: 7px !important;
    resize: none;
    height: 40px;
    color: #dfdee2;
    font: normal normal normal 14px GilroyLight;
  }
  
  .FormControlMask:focus-visible {
    outline: rgbargb(0 7 58) !important;
  }
  
  .FormSelect {
    background-color: rgb(0 7 58) !important;
    border-radius: 7px !important;
    border: 1px solid rgba(18 214 223 / 30%);
    height: 40px;
    color: #dfdee2;
    font: normal normal normal 14px GilroyLight;
  }
  
  .FormSelect:focus {
    border-color: rgb(0 7 58) !important;
  }
  
  .Line {
    color: #12d6df;
    opacity: 1;
  }
  
  /* const FormCheckInput = styled("div") {
    & input {
      background-color: transparent;
      border: 2px solid #12d6df;
      width: 15px;
      height: 15px;
    }
  
    & input:focus {
      box-shadow: none;
    }
  
    & input:checked[type="checkbox"] {
      background-color: #12d6df;
      border: 2px solid #12d6df;
    }
  }; */
  
  .InfoField {
    display: block;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    border: 1px solid rgba(18 214 223 / 30%);
    width: 50%;
    padding: 10px;
  }
  
  .Icon {
    margin-right: 16px;
  }
  