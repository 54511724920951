.vdo-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 oranı korumak için */
  height: 0;
  overflow: hidden;
}

.vdo-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vdo-container-mobile {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 oranı korumak için */
  height: 0;
  overflow: hidden;
}

.vdo-container-mobile iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Player {
  display: "none";
}

