.api-controls {
  margin-top: 1rem;
  background: white;
  border-radius: 4px;
  padding: 5px;
  border-bottom: none !important;
  align-items: center;
}

.api-controls .btn {
  border: 1px solid;
  padding: 5px;
  border-radius: 4px;
}

.api-controls .btn:hover {
  cursor: pointer;
}

.skip-button {
  position: absolute;
  bottom: 75px;
  left: 5%;
  background: #bc00bc;
  padding: 5px 25px;
  z-index: 20;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.skip-button:hover {
  background: white;
  color: #bc00bc;
}